.header {
  display: flex;
  justify-content: space-between;
  width: calc(90%);
  margin: 10px;
  height: 10vh;
  min-height: 50px;
  padding-left: 2.5%;
  padding-right: 2.5%;
}

ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.navLink.active {
  text-decoration: underline;
}

.navLink {
  color: var(--primary-light);
  text-decoration: none;
  margin: 10px;
}

a {
  color: inherit;
  /* text-decoration: none; */
  margin: 10px;
}

.navLink:hover {
  background-color: #474c5a;
}

.App-logo {
  height: 10vh;
  min-height: 50px;
}
