.info-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-square {
  width: 100%;
  height: 100%;
  background-color: var(--primary-light);
  color: var(--primary-dark);

  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  border-radius: 10px;

  border: 1px solid var(--primary-light);
  box-shadow: 0 3px 10px var(--primary-light-05);
}
.info-card .subtitle{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  
}

.info-card .title{
  text-align: center;
  
}

@media screen and (min-width: 768px) {
  .info-square {
    font-size: 85%;
  }
}
  

.square-animation {
  animation: wipe-enter 1s 1;
}
