section {
  width: 100%;
  height: 90vh;
  border-bottom: 2px solid black;
}

section.info-section {
  height: 100vh;
}

.bio {
  display: flex;
  gap: 50px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.bio-text {
  flex-basis: 22rem;

  display: flex;
  min-width: 3rem;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.bio-photo-card{
  position:relative;
  height: 50%;
  width: 300px;
  padding: 0;
  perspective: 1000px;
  --animation-period: 0.25s;

    /* opacity: 0;
    animation: fade-in 1s 0s ease-out forwards, jump-in 2s 0s ease-out forwards; */
}
.photo-me, .photo-back {
  /* width: 15%; */
  position:absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;


}

.photo-me{
  /* text-align: start; */
  transform: rotateY(0deg);
  animation: flip-back var(--animation-period) ease-in-out;
  border-radius: 20px;
  border: 5px solid #f7f6e9;
  object-fit: cover;

}
.photo-back{
  transform: rotateY(180deg);

  animation: flip-front var(--animation-period) ease-in-out;

}
.bio-photo-card:hover .photo-me {
  animation: flip-front var(--animation-period) ease-in-out;

  transform: rotateY(180deg);
}


.bio-photo-card:hover .photo-back {
  animation: flip-back var(--animation-period) ease-in-out;

  transform: rotateY(0deg);
}

@keyframes flip-front {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(-90deg);
  }	
  100% {
    transform: rotateY(-180deg);
  }
}

@keyframes flip-back {
  0% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(90deg);
  }	
  100% {
    transform: rotateY(0deg);
  }
}/*

@keyframes jump-in {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
} */

/* @media (min-width: 280px) {
  .photo-me {
    display: none;
  }
}

@media (min-width: 700px) {
  .photo-me {
    display: block;
  }
} */

.typewriter-container {
  display: inline-block;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid transparent; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  width: 0;
}

.typewriter.line1 {
  animation: typing 1.6s steps(30, end) forwards, blink-caret 0.75s step-end 2;
}

.typewriter.line2 {
  animation: typing 1.8s steps(30, end) forwards, blink-caret 0.75s step-end 3;
  animation-delay: 2.05s;
}

.typewriter.line3 {
  animation: typing 1.8s steps(30, end) forwards,
    blink-caret 0.75s step-end infinite;
  animation-delay: 4.35s;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: white;
  }
  50% {
    border-color: transparent;
  }
}
