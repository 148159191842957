.contactInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  opacity: 0;
  animation: fade-in 0.5s 0.5s ease-out forwards,
    jump-in 2s 0.5s ease-out forwards infinite;
}

.contactInfo .info-card {
  flex-basis: 15rem;
}

.contactInfo .info-card .info-square:hover {
  background-color: var(--primary-dark);
  color: var(--primary-light);
}

@keyframes fade-in {
  0% {
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes jump-in {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
}
